/* Page Setup + Mixins */

$breakXLNoUnit: 1440;
$breakXL: 1440px;
$breakL: 1200px;
$breakM: 950px;
$breakS: 768px;
$breakSNoUnit: 768;

:root {
  --gutter: 14px;
  --pageMarginTotal: 60px;
  --pageMargin: calc(var(--pageMarginTotal) - (var(--gutter) / 2));
  --easingOut: cubic-bezier(0.16, 1, 0.3, 1);
  --easing: cubic-bezier(0.87, 0, 0.13, 1);
  --dark-blue: #081834;
  --mid-blue: #485361;
  --light-blue: #119da4;
  --yellow: #f3f24e;
  --black: #000;
  --white: #fff;

  @media all and (max-width: $breakS) {
    --pageMarginTotal: 30px;
  }
}

@mixin fluidProp($prop, $min, $max) {
  #{$prop}: #{$min + "px"};
  $diff: ($max - $min);
  $screenDiff: ($breakXLNoUnit - $breakSNoUnit);

  @media screen and (min-width: $breakS) {
    #{$prop}: calc(#{$min + "px"} + #{$diff} * ((100vw - #{$breakS}) / #{$screenDiff}));
  }

  @media screen and (min-width: $breakXL) {
    #{$prop}: #{$max + "px"};
  }
}

@import "reset";
@import "grid";
@import "typography";

/* Colours */

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.color-dark-blue {
  color: var(--dark-blue);
}

.bg-mid-blue {
  background-color: var(--mid-blue);
}

.color-mid-blue {
  color: var(--mid-blue);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.color-light-blue {
  color: var(--light-blue);
}

.bg-yellow {
  background-color: var(--yellow);
}

.color-yellow {
  color: var(--yellow);
}

.bg-black {
  background-color: var(--black);
}

.color-black {
  color: var(--black);
}

.bg-white {
  background-color: var(--white);
}

.color-white {
  color: var(--white);
}

/* Spacers */

/* Grid Overlay */

.grid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .column-overlay {
    background: red;
    opacity: 0.1;
    height: 100vh;
  }
}

/* General Styles */

body {
  font-family: "DMSansWeb", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include fluidProp("font-size", 16, 26);
  line-height: 1.26em;
}

a {
  text-decoration: none;
}

.flow a,
section a {
  text-decoration: underline;
  color: currentColor;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;

  &:hover {
    opacity: .7;
  }
}

main .flow ul,
main .flow ol {
  list-style: disc;
  margin-left: 30px;
}

main .flow ol {
  list-style: decimal;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  text-align: center;
  padding: 60px 0;
  pointer-events: none;
  transition: all .6s var(--easingOut);

  .header-logo {
    display: inline-block;
    color: var(--black);
    font-size: 113px;
    height: 113px;
    pointer-events: all;
    transition: all .6s var(--easingOut);
  }

  &.scrolled {
    padding: 15px 0;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

    .header-logo {
      font-size: 50px;
      height: 50px;
    }
  }
}

.hero-slider {
  position: relative;

  .cta {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 40px;
    color: var(--dark-blue);
    z-index: 25;

    &:hover {
      color: var(--yellow);
    }
  }

  .slide {
    width: 100%;
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .flickity-button {
    background: transparent;
  }

  .flickity-prev-next-button {
    width: 75px;
    height: 75px;
  }

  .flickity-button-icon {
    fill: var(--dark-blue);

    &:hover {
      fill: var(--yellow);
    }
  }

  .flickity-button:disabled {
    display: none;
  }
}

.media {
  position: relative;
  width: 100%;

  &.natural {
    width: 100%;
    height: auto;

    img,
    video {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &.landscape,
  &.portrait,
  &.square {
    overflow: hidden;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.landscape {
    aspect-ratio: 43 / 26;
  }

  &.portrait {
    aspect-ratio: 9 / 16;
  }

  &.square {
    aspect-ratio: 1;
  }
}

section {
  @include fluidProp('padding-top', 60, 90);
  @include fluidProp('padding-bottom', 60, 90);

  .columns+.columns {
    @include fluidProp('margin-top', 60, 90);
  }

  .section-title {
    text-align: center;
  }
}

.nutshell-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  column-gap: 125px;
  row-gap: 80px;
  padding: 0 40px;

  @media all and (max-width: $breakS) {
    grid-template-columns: 1fr;
  }

  .stat {
    @include fluidProp('font-size', 20, 31);
    line-height: 1.16em;

    .stat-number {
      @include fluidProp('font-size', 30, 70);
      line-height: 1.3em;
      font-weight: 700;
      margin-bottom: 10px;

      span {
        @include fluidProp('font-size', 50, 107);
      }
    }
  }
}

form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  label {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    width: 100%;
    font-weight: 700;
    align-items: flex-start;
    cursor: pointer;

    &.half-width {
      width: calc((100% - 30px) / 2);
    }

    sup {
      color: var(--yellow);
      margin-left: 3px;
    }

    span {
      display: block;
      width: calc(100% - 20px);
      padding-left: 20px;
    }

    input[type=radio],
    input[type=checkbox] {
      position: relative;
      top: 8px;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    padding: 10px;
    font-family: "DMSansWeb", sans-serif;
    @include fluidProp("font-size", 16, 26);
    color: var(--dark-blue);
    border: 0;
    border: 1px solid white;
    outline: 0;
    appearance: none;
    border-radius: 0;

    &:focus {
      border: 1px solid var(--dark-blue);
      box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
    }
  }

  textarea {
    resize: none;
    min-height: 200px;
  }

  [type=submit] {
    background-color: var(--yellow);
    border: 0;
    outline: 0;
    appearance: none;
    border-radius: 0;
    font-weight: 700;
    padding: 5px 30px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      background-color: white;
    }
  }
}

main {
  &.no-header {
    margin-top: 200px
  }
}


footer {
  background-color: var(--mid-blue);
  color: var(--white);
  border-top: 1px solid var(--white);
  padding-top: 110px;
  padding-bottom: 80px;
  font-size: 15px;

  .columns {
    align-items: flex-end;

    .col:last-child {
      text-align: right;
    }

    .col {
      --flow-space: 30px;
    }
  }

  h3 {
    color: var(--yellow);
  }

  img {
    display: inline-block;
  }

  ul:not(.social) {
    display: flex;
    gap: 20px;

    a {
      text-decoration: none;
      color: var(--yellow);
    }
  }
}

ul.social {
  display: flex;
  gap: 25px;

  a {
    font-size: 28px;
  }
}

#cm {
  background-color: var(--white);
  color: var(--dark-blue);
  padding: 15px !important;
  @include fluidProp("font-size", 10, 14);

  @media all and (max-width: $breakS) {
    padding: 24px 16px !important;
  }
}

#cm.bar #c-inr {
  max-width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media all and (max-width: $breakS) {
    justify-content: space-between;
  }
}

.cc_div #c-bns {
  margin-top: 0;
  @include fluidProp("margin-left", 0, 60);
  gap: 12px;

  @media all and (max-width: $breakS) {
    flex-direction: row;
  }
}

#cm.bar #c-bns {
  max-width: 100%;
}

#c-txt {
  @include fluidProp("font-size", 10, 14);

  @media all and (max-width: $breakS) {
    max-width: 200px;
  }
}

.cc_div a,
.cc_div button,
.cc_div input {
  overflow: visible;
}

#c-s-bn {
  opacity: .8;
}

#c-bns button:first-child,
#s-bns button:first-child {
  background-color: transparent;
  color: var(--black);
  padding: 0;
}

.cc_div .c-bn {
  background-color: transparent;
  color: var(--ibn-off-black);
  padding: 0;
  @include fluidProp("font-size", 10, 14);
  border-radius: 0;
  font-weight: 400;
  text-decoration: 1px solid underline;
  text-decoration-color: #636461;
  text-underline-offset: 4px;
  flex: none;
}

#c-bns button:first-child:active,
#c-bns button:first-child:hover,
#c-bns.swap button:last-child:active,
#c-bns.swap button:last-child:hover,
#s-bns button:first-child:active,
#s-bns button:first-child:hover,
#cm .c_link:active,
#cm .c_link:hover,
#s-c-bn:active,
#s-c-bn:hover,
#s-cnt button+button:active,
#s-cnt button+button:hover {
  background-color: transparent;
  text-decoration-color: var(--black);
}

#cm .c-bn,
.cc_div .c-bn {
  width: auto !important;
}

#c-bns button+button,
#s-cnt button+button {
  float: none;
  margin-left: 0;

  @media all and (max-width: $breakS) {
    margin-top: 0;
  }
}