:root {
  --gutter: 14px;
  --pageMarginTotal: 60px;
  --pageMargin: calc(var(--pageMarginTotal)  - (var(--gutter) / 2));
  --easingOut: cubic-bezier(.16, 1, .3, 1);
  --easing: cubic-bezier(.87, 0, .13, 1);
  --dark-blue: #081834;
  --mid-blue: #485361;
  --light-blue: #119da4;
  --yellow: #f3f24e;
  --black: #000;
  --white: #fff;
}

@media (max-width: 768px) {
  :root {
    --pageMarginTotal: 30px;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

.icon {
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  display: inline-block;
}

.icon-wandsworth-gasworks {
  width: 1.99316em;
}

.flow > * + * {
  margin-block-start: var(--flow-space, 1em);
}

.text-content :is(h2 + *, h3 + *, h4 + *) {
  --flow-space: .5em;
}

.text-content {
  --flow-space: 1.5em;
}

.columns {
  padding: 0 var(--pageMarginTotal);
  width: 100%;
  column-gap: var(--gutter);
  --columns: 12;
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 768px) {
  .columns {
    row-gap: var(--gutter);
  }
}

.columns .columns {
  padding: 0;
}

@media (max-width: 768px) {
  .columns.mobile-reverse {
    flex-direction: column-reverse;
  }
}

.columns.no-gutter {
  --gutter: 0;
}

.columns.space-between {
  justify-content: space-between;
}

.full-bleed {
  --pageMarginTotal: 0;
}

.col {
  flex: 1;
}

@media (max-width: 768px) {
  .col {
    width: 100%;
    flex: none;
  }
}

.col[class^="col-"], .col[class*=" col-"] {
  width: calc((100% - var(--gutter) * (var(--columns) / var(--span)  - 1)) / (var(--columns) / var(--span)));
  flex: none;
}

.col[class^="offset-"], .col[class*=" offset-"] {
  margin-left: calc((100% - var(--gutter) * (var(--columns) / var(--offset)  - 1)) / (var(--columns) / var(--offset))  + var(--gutter));
}

.col[class^="offset-s-"], .col[class*=" offset-s-"] {
  margin-left: calc((100% - var(--gutter) * (var(--columns) / var(--offset)  - 1)) / (var(--columns) / var(--offset))  + var(--gutter)) !important;
}

.col-1 {
  --span: 1;
}

.col-2 {
  --span: 2;
}

.col-3 {
  --span: 3;
}

.col-4 {
  --span: 4;
}

.col-5 {
  --span: 5;
}

.col-6 {
  --span: 6;
}

.col-7 {
  --span: 7;
}

.col-8 {
  --span: 8;
}

.col-9 {
  --span: 9;
}

.col-10 {
  --span: 10;
}

.col-11 {
  --span: 11;
}

.col-12 {
  --span: 12;
}

.col-13 {
  --span: 13;
}

.col-14 {
  --span: 14;
}

.col-15 {
  --span: 15;
}

.col-16 {
  --span: 16;
}

.col-17 {
  --span: 17;
}

.col-18 {
  --span: 18;
}

.col-19 {
  --span: 19;
}

.col-20 {
  --span: 20;
}

.col-21 {
  --span: 21;
}

.col-22 {
  --span: 22;
}

.col-23 {
  --span: 23;
}

.col-24 {
  --span: 24;
}

.offset-1 {
  --offset: 1;
}

.offset-2 {
  --offset: 2;
}

.offset-3 {
  --offset: 3;
}

.offset-4 {
  --offset: 4;
}

.offset-5 {
  --offset: 5;
}

.offset-6 {
  --offset: 6;
}

.offset-7 {
  --offset: 7;
}

.offset-8 {
  --offset: 8;
}

.offset-9 {
  --offset: 9;
}

.offset-10 {
  --offset: 10;
}

.offset-11 {
  --offset: 11;
}

.offset-12 {
  --offset: 12;
}

.offset-13 {
  --offset: 13;
}

.offset-14 {
  --offset: 14;
}

.offset-15 {
  --offset: 15;
}

.offset-16 {
  --offset: 16;
}

.offset-17 {
  --offset: 17;
}

.offset-18 {
  --offset: 18;
}

.offset-19 {
  --offset: 19;
}

.offset-20 {
  --offset: 20;
}

.offset-21 {
  --offset: 21;
}

.offset-22 {
  --offset: 22;
}

.offset-23 {
  --offset: 23;
}

@media (max-width: 1200px) {
  .col-l-1 {
    --span: 1;
  }

  .col-l-2 {
    --span: 2;
  }

  .col-l-3 {
    --span: 3;
  }

  .col-l-4 {
    --span: 4;
  }

  .col-l-5 {
    --span: 5;
  }

  .col-l-6 {
    --span: 6;
  }

  .col-l-7 {
    --span: 7;
  }

  .col-l-8 {
    --span: 8;
  }

  .col-l-9 {
    --span: 9;
  }

  .col-l-10 {
    --span: 10;
  }

  .col-l-11 {
    --span: 11;
  }

  .col-l-12 {
    --span: 12;
  }

  .col-l-13 {
    --span: 13;
  }

  .col-l-14 {
    --span: 14;
  }

  .col-l-15 {
    --span: 15;
  }

  .col-l-16 {
    --span: 16;
  }

  .col-l-17 {
    --span: 17;
  }

  .col-l-18 {
    --span: 18;
  }

  .col-l-19 {
    --span: 19;
  }

  .col-l-20 {
    --span: 20;
  }

  .col-l-21 {
    --span: 21;
  }

  .col-l-22 {
    --span: 22;
  }

  .col-l-23 {
    --span: 23;
  }

  .col-l-24 {
    --span: 24;
  }

  .offset-l-0 {
    margin-left: 0 !important;
  }

  .offset-l-1 {
    --offset: 1;
  }

  .offset-l-2 {
    --offset: 2;
  }

  .offset-l-3 {
    --offset: 3;
  }

  .offset-l-4 {
    --offset: 4;
  }

  .offset-l-5 {
    --offset: 5;
  }

  .offset-l-6 {
    --offset: 6;
  }

  .offset-l-7 {
    --offset: 7;
  }

  .offset-l-8 {
    --offset: 8;
  }

  .offset-l-9 {
    --offset: 9;
  }

  .offset-l-10 {
    --offset: 10;
  }

  .offset-l-11 {
    --offset: 11;
  }

  .offset-l-12 {
    --offset: 12;
  }

  .offset-l-13 {
    --offset: 13;
  }

  .offset-l-14 {
    --offset: 14;
  }

  .offset-l-15 {
    --offset: 15;
  }

  .offset-l-16 {
    --offset: 16;
  }

  .offset-l-17 {
    --offset: 17;
  }

  .offset-l-18 {
    --offset: 18;
  }

  .offset-l-19 {
    --offset: 19;
  }

  .offset-l-20 {
    --offset: 20;
  }

  .offset-l-21 {
    --offset: 21;
  }

  .offset-l-22 {
    --offset: 22;
  }

  .offset-l-23 {
    --offset: 23;
  }
}

@media (max-width: 950px) {
  .col-m-1 {
    --span: 1;
  }

  .col-m-2 {
    --span: 2;
  }

  .col-m-3 {
    --span: 3;
  }

  .col-m-4 {
    --span: 4;
  }

  .col-m-5 {
    --span: 5;
  }

  .col-m-6 {
    --span: 6;
  }

  .col-m-7 {
    --span: 7;
  }

  .col-m-8 {
    --span: 8;
  }

  .col-m-9 {
    --span: 9;
  }

  .col-m-10 {
    --span: 10;
  }

  .col-m-11 {
    --span: 11;
  }

  .col-m-12 {
    --span: 12;
  }

  .col-m-13 {
    --span: 13;
  }

  .col-m-14 {
    --span: 14;
  }

  .col-m-15 {
    --span: 15;
  }

  .col-m-16 {
    --span: 16;
  }

  .col-m-17 {
    --span: 17;
  }

  .col-m-18 {
    --span: 18;
  }

  .col-m-19 {
    --span: 19;
  }

  .col-m-20 {
    --span: 20;
  }

  .col-m-21 {
    --span: 21;
  }

  .col-m-22 {
    --span: 22;
  }

  .col-m-23 {
    --span: 23;
  }

  .col-m-24 {
    --span: 24;
  }

  .offset-m-0 {
    margin-left: 0 !important;
  }

  .offset-m-1 {
    --offset: 1;
  }

  .offset-m-2 {
    --offset: 2;
  }

  .offset-m-3 {
    --offset: 3;
  }

  .offset-m-4 {
    --offset: 4;
  }

  .offset-m-5 {
    --offset: 5;
  }

  .offset-m-6 {
    --offset: 6;
  }

  .offset-m-7 {
    --offset: 7;
  }

  .offset-m-8 {
    --offset: 8;
  }

  .offset-m-9 {
    --offset: 9;
  }

  .offset-m-10 {
    --offset: 10;
  }

  .offset-m-11 {
    --offset: 11;
  }

  .offset-m-12 {
    --offset: 12;
  }

  .offset-m-13 {
    --offset: 13;
  }

  .offset-m-14 {
    --offset: 14;
  }

  .offset-m-15 {
    --offset: 15;
  }

  .offset-m-16 {
    --offset: 16;
  }

  .offset-m-17 {
    --offset: 17;
  }

  .offset-m-18 {
    --offset: 18;
  }

  .offset-m-19 {
    --offset: 19;
  }

  .offset-m-20 {
    --offset: 20;
  }

  .offset-m-21 {
    --offset: 21;
  }

  .offset-m-22 {
    --offset: 22;
  }

  .offset-m-23 {
    --offset: 23;
  }
}

@media (max-width: 768px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col-l-1, .col-l-2, .col-l-3, .col-l-4, .col-l-5, .col-l-6, .col-l-7, .col-l-8, .col-l-9, .col-l-10, .col-l-11, .col-l-12, .col-l-13, .col-l-14, .col-l-15, .col-l-16, .col-l-17, .col-l-18, .col-l-19, .col-l-20, .col-l-21, .col-l-22, .col-l-23, .col-l-24, .col-m-1, .col-m-2, .col-m-3, .col-m-4, .col-m-5, .col-m-6, .col-m-7, .col-m-8, .col-m-9, .col-m-10, .col-m-11, .col-m-12, .col-m-13, .col-m-14, .col-m-15, .col-m-16, .col-m-17, .col-m-18, .col-m-19, .col-m-20, .col-m-21, .col-m-22, .col-m-23, .col-m-24 {
    --span: 12;
  }

  .offset-1, .offset-2, .offset-3, .offset-4, .offset-5, .offset-6, .offset-7, .offset-8, .offset-9, .offset-10, .offset-11, .offset-12, .offset-13, .offset-14, .offset-15, .offset-16, .offset-17, .offset-18, .offset-19, .offset-20, .offset-21, .offset-22, .offset-23 {
    --offset: 0;
    margin-left: 0 !important;
  }

  .col-s-1 {
    --span: 1;
  }

  .col-s-2 {
    --span: 2;
  }

  .col-s-3 {
    --span: 3;
  }

  .col-s-4 {
    --span: 4;
  }

  .col-s-5 {
    --span: 5;
  }

  .col-s-6 {
    --span: 6;
  }

  .col-s-7 {
    --span: 7;
  }

  .col-s-8 {
    --span: 8;
  }

  .col-s-9 {
    --span: 9;
  }

  .col-s-10 {
    --span: 10;
  }

  .col-s-11 {
    --span: 11;
  }

  .col-s-12 {
    --span: 12;
  }

  .col-s-13 {
    --span: 13;
  }

  .col-s-14 {
    --span: 14;
  }

  .col-s-15 {
    --span: 15;
  }

  .col-s-16 {
    --span: 16;
  }

  .col-s-17 {
    --span: 17;
  }

  .col-s-18 {
    --span: 18;
  }

  .col-s-19 {
    --span: 19;
  }

  .col-s-20 {
    --span: 20;
  }

  .col-s-21 {
    --span: 21;
  }

  .col-s-22 {
    --span: 22;
  }

  .col-s-23 {
    --span: 23;
  }

  .col-s-24 {
    --span: 24;
  }

  .offset-s-0 {
    margin-left: 0 !important;
  }

  .offset-s-1 {
    --offset: 1;
  }

  .offset-s-2 {
    --offset: 2;
  }

  .offset-s-3 {
    --offset: 3;
  }

  .offset-s-4 {
    --offset: 4;
  }

  .offset-s-5 {
    --offset: 5;
  }

  .offset-s-6 {
    --offset: 6;
  }

  .offset-s-7 {
    --offset: 7;
  }

  .offset-s-8 {
    --offset: 8;
  }

  .offset-s-9 {
    --offset: 9;
  }

  .offset-s-10 {
    --offset: 10;
  }

  .offset-s-11 {
    --offset: 11;
  }

  .offset-s-12 {
    --offset: 12;
  }

  .offset-s-13 {
    --offset: 13;
  }

  .offset-s-14 {
    --offset: 14;
  }

  .offset-s-15 {
    --offset: 15;
  }

  .offset-s-16 {
    --offset: 16;
  }

  .offset-s-17 {
    --offset: 17;
  }

  .offset-s-18 {
    --offset: 18;
  }

  .offset-s-19 {
    --offset: 19;
  }

  .offset-s-20 {
    --offset: 20;
  }

  .offset-s-21 {
    --offset: 21;
  }

  .offset-s-22 {
    --offset: 22;
  }

  .offset-s-23 {
    --offset: 23;
  }
}

.show-small {
  display: none;
}

@media (max-width: 768px) {
  .show-small {
    display: flex;
  }

  .hide-small {
    display: none;
  }

  .small-right {
    text-align: right;
  }
}

@font-face {
  font-family: DMSansWeb;
  src: url("DMSans-Bold.0451eedb.woff2") format("woff2"), url("DMSans-Bold.4c3c21f9.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: DMSansWeb;
  src: url("DMSans-Regular.17870eaa.woff2") format("woff2"), url("DMSans-Regular.3299cfd3.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: MinionProWeb;
  src: url("MinionPro-Regular.8c2c4097.woff2") format("woff2"), url("MinionPro-Regular.6d43356c.woff") format("woff"), url("MinionPro-Regular.818db390.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

h1, h2 {
  font-family: Oswald, sans-serif;
}

h2 {
  font-size: 35px;
  line-height: 1.33em;
}

@media screen and (min-width: 768px) {
  h2 {
    font-size: calc(3.125vw + 11px);
  }
}

@media screen and (min-width: 1440px) {
  h2 {
    font-size: 56px;
  }
}

h3 {
  font-size: 23px;
}

@media screen and (min-width: 768px) {
  h3 {
    font-size: calc(.446429vw + 19.5714px);
  }
}

@media screen and (min-width: 1440px) {
  h3 {
    font-size: 26px;
  }
}

b, strong {
  font-weight: 700;
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.color-dark-blue {
  color: var(--dark-blue);
}

.bg-mid-blue {
  background-color: var(--mid-blue);
}

.color-mid-blue {
  color: var(--mid-blue);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.color-light-blue {
  color: var(--light-blue);
}

.bg-yellow {
  background-color: var(--yellow);
}

.color-yellow {
  color: var(--yellow);
}

.bg-black {
  background-color: var(--black);
}

.color-black {
  color: var(--black);
}

.bg-white {
  background-color: var(--white);
}

.color-white {
  color: var(--white);
}

.grid-overlay {
  width: 100%;
  height: 100vh;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

.grid-overlay.show {
  opacity: 1;
  visibility: visible;
}

.grid-overlay .column-overlay {
  opacity: .1;
  height: 100vh;
  background: red;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: DMSansWeb, sans-serif;
  font-size: 16px;
  line-height: 1.26em;
}

@media screen and (min-width: 768px) {
  body {
    font-size: calc(1.4881vw + 4.57143px);
  }
}

@media screen and (min-width: 1440px) {
  body {
    font-size: 26px;
  }
}

a {
  text-decoration: none;
}

.flow a, section a {
  color: currentColor;
  text-underline-offset: 3px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.flow a:hover, section a:hover {
  opacity: .7;
}

main .flow ul, main .flow ol {
  margin-left: 30px;
  list-style: disc;
}

main .flow ol {
  list-style: decimal;
}

header {
  width: 100%;
  z-index: 100;
  text-align: center;
  pointer-events: none;
  transition: all .6s var(--easingOut);
  padding: 60px 0;
  position: fixed;
  top: 0;
  left: 0;
}

header .header-logo {
  color: var(--black);
  height: 113px;
  pointer-events: all;
  transition: all .6s var(--easingOut);
  font-size: 113px;
  display: inline-block;
}

header.scrolled {
  background-color: #fff;
  padding: 15px 0;
  box-shadow: 0 5px 10px #0003;
}

header.scrolled .header-logo {
  height: 50px;
  font-size: 50px;
}

.hero-slider {
  position: relative;
}

.hero-slider .cta {
  color: var(--dark-blue);
  z-index: 25;
  font-size: 40px;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}

.hero-slider .cta:hover {
  color: var(--yellow);
}

.hero-slider .slide {
  width: 100%;
  height: 100vh;
}

.hero-slider .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-slider .flickity-button {
  background: none;
}

.hero-slider .flickity-prev-next-button {
  width: 75px;
  height: 75px;
}

.hero-slider .flickity-button-icon {
  fill: var(--dark-blue);
}

.hero-slider .flickity-button-icon:hover {
  fill: var(--yellow);
}

.hero-slider .flickity-button:disabled {
  display: none;
}

.media {
  width: 100%;
  position: relative;
}

.media.natural {
  width: 100%;
  height: auto;
}

.media.natural img, .media.natural video {
  width: 100%;
  height: auto;
  display: block;
}

.media.landscape, .media.portrait, .media.square {
  overflow: hidden;
}

.media.landscape img, .media.landscape video, .media.portrait img, .media.portrait video, .media.square img, .media.square video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media.landscape {
  aspect-ratio: 43 / 26;
}

.media.portrait {
  aspect-ratio: 9 / 16;
}

.media.square {
  aspect-ratio: 1;
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (min-width: 768px) {
  section {
    padding-top: calc(4.46429vw + 25.7143px);
  }
}

@media screen and (min-width: 1440px) {
  section {
    padding-top: 90px;
  }
}

@media screen and (min-width: 768px) {
  section {
    padding-bottom: calc(4.46429vw + 25.7143px);
  }
}

@media screen and (min-width: 1440px) {
  section {
    padding-bottom: 90px;
  }
}

section .columns + .columns {
  margin-top: 60px;
}

@media screen and (min-width: 768px) {
  section .columns + .columns {
    margin-top: calc(4.46429vw + 25.7143px);
  }
}

@media screen and (min-width: 1440px) {
  section .columns + .columns {
    margin-top: 90px;
  }
}

section .section-title {
  text-align: center;
}

.nutshell-stats {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 80px 125px;
  padding: 0 40px;
  display: grid;
}

@media (max-width: 768px) {
  .nutshell-stats {
    grid-template-columns: 1fr;
  }
}

.nutshell-stats .stat {
  font-size: 20px;
  line-height: 1.16em;
}

@media screen and (min-width: 768px) {
  .nutshell-stats .stat {
    font-size: calc(1.6369vw + 7.42857px);
  }
}

@media screen and (min-width: 1440px) {
  .nutshell-stats .stat {
    font-size: 31px;
  }
}

.nutshell-stats .stat .stat-number {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.3em;
}

@media screen and (min-width: 768px) {
  .nutshell-stats .stat .stat-number {
    font-size: calc(5.95238vw - 15.7143px);
  }
}

@media screen and (min-width: 1440px) {
  .nutshell-stats .stat .stat-number {
    font-size: 70px;
  }
}

.nutshell-stats .stat .stat-number span {
  font-size: 50px;
}

@media screen and (min-width: 768px) {
  .nutshell-stats .stat .stat-number span {
    font-size: calc(8.48214vw - 15.1429px);
  }
}

@media screen and (min-width: 1440px) {
  .nutshell-stats .stat .stat-number span {
    font-size: 107px;
  }
}

form {
  flex-wrap: wrap;
  gap: 30px;
  display: flex;
}

form label {
  width: 100%;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}

form label.half-width {
  width: calc(50% - 15px);
}

form label sup {
  color: var(--yellow);
  margin-left: 3px;
}

form label span {
  width: calc(100% - 20px);
  padding-left: 20px;
  display: block;
}

form label input[type="radio"], form label input[type="checkbox"] {
  position: relative;
  top: 8px;
}

form input[type="text"], form input[type="email"], form input[type="tel"], form textarea {
  width: 100%;
  color: var(--dark-blue);
  appearance: none;
  border: 1px solid #fff;
  border-radius: 0;
  outline: 0;
  padding: 10px;
  font-family: DMSansWeb, sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

@media screen and (min-width: 768px) {
  form input[type="text"], form input[type="email"], form input[type="tel"], form textarea {
    font-size: calc(1.4881vw + 4.57143px);
  }
}

@media screen and (min-width: 1440px) {
  form input[type="text"], form input[type="email"], form input[type="tel"], form textarea {
    font-size: 26px;
  }
}

form input[type="text"]:focus, form input[type="email"]:focus, form input[type="tel"]:focus, form textarea:focus {
  border: 1px solid var(--dark-blue);
  box-shadow: 0 5px 5px #0003;
}

form textarea {
  resize: none;
  min-height: 200px;
}

form [type="submit"] {
  background-color: var(--yellow);
  appearance: none;
  cursor: pointer;
  text-transform: uppercase;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 5px 30px;
  font-weight: 700;
}

form [type="submit"]:hover {
  background-color: #fff;
}

main.no-header {
  margin-top: 200px;
}

footer {
  background-color: var(--mid-blue);
  color: var(--white);
  border-top: 1px solid var(--white);
  padding-top: 110px;
  padding-bottom: 80px;
  font-size: 15px;
}

footer .columns {
  align-items: flex-end;
}

footer .columns .col:last-child {
  text-align: right;
}

footer .columns .col {
  --flow-space: 30px;
}

footer h3 {
  color: var(--yellow);
}

footer img {
  display: inline-block;
}

footer ul:not(.social) {
  gap: 20px;
  display: flex;
}

footer ul:not(.social) a {
  color: var(--yellow);
  text-decoration: none;
}

ul.social {
  gap: 25px;
  display: flex;
}

ul.social a {
  font-size: 28px;
}

#cm {
  background-color: var(--white);
  color: var(--dark-blue);
  font-size: 10px;
  padding: 15px !important;
}

@media screen and (min-width: 768px) {
  #cm {
    font-size: calc(.595238vw + 5.42857px);
  }
}

@media screen and (min-width: 1440px) {
  #cm {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  #cm {
    padding: 24px 16px !important;
  }
}

#cm.bar #c-inr {
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  display: flex;
}

@media (max-width: 768px) {
  #cm.bar #c-inr {
    justify-content: space-between;
  }
}

.cc_div #c-bns {
  gap: 12px;
  margin-top: 0;
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .cc_div #c-bns {
    margin-left: calc(8.92857vw - 68.5714px);
  }
}

@media screen and (min-width: 1440px) {
  .cc_div #c-bns {
    margin-left: 60px;
  }
}

@media (max-width: 768px) {
  .cc_div #c-bns {
    flex-direction: row;
  }
}

#cm.bar #c-bns {
  max-width: 100%;
}

#c-txt {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  #c-txt {
    font-size: calc(.595238vw + 5.42857px);
  }
}

@media screen and (min-width: 1440px) {
  #c-txt {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  #c-txt {
    max-width: 200px;
  }
}

.cc_div a, .cc_div button, .cc_div input {
  overflow: visible;
}

#c-s-bn {
  opacity: .8;
}

#c-bns button:first-child, #s-bns button:first-child {
  color: var(--black);
  background-color: #0000;
  padding: 0;
}

.cc_div .c-bn {
  color: var(--ibn-off-black);
  text-underline-offset: 4px;
  background-color: #0000;
  border-radius: 0;
  flex: none;
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  -webkit-text-decoration: underline #636461;
  text-decoration: underline #636461;
  text-decoration-thickness: 1px;
}

@media screen and (min-width: 768px) {
  .cc_div .c-bn {
    font-size: calc(.595238vw + 5.42857px);
  }
}

@media screen and (min-width: 1440px) {
  .cc_div .c-bn {
    font-size: 14px;
  }
}

#c-bns button:first-child:active, #c-bns button:first-child:hover, #c-bns.swap button:last-child:active, #c-bns.swap button:last-child:hover, #s-bns button:first-child:active, #s-bns button:first-child:hover, #cm .c_link:active, #cm .c_link:hover, #s-c-bn:active, #s-c-bn:hover, #s-cnt button + button:active, #s-cnt button + button:hover {
  -webkit-text-decoration-color: var(--black);
  text-decoration-color: var(--black);
  background-color: #0000;
}

#cm .c-bn, .cc_div .c-bn {
  width: auto !important;
}

#c-bns button + button, #s-cnt button + button {
  float: none;
  margin-left: 0;
}

@media (max-width: 768px) {
  #c-bns button + button, #s-cnt button + button {
    margin-top: 0;
  }
}

/*# sourceMappingURL=index.60ed7f8c.css.map */
