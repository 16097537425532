@font-face {
	font-family: "DMSansWeb";
	src: url("../fonts/DMSans-Bold.woff2") format("woff2"), url("../fonts/DMSans-Bold.woff") format("woff");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "DMSansWeb";
	src: url("../fonts/DMSans-Regular.woff2") format("woff2"), url("../fonts/DMSans-Regular.woff") format("woff");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "MinionProWeb";
	src: url("../fonts/MinionPro-Regular.woff2") format("woff2"), url("../fonts/MinionPro-Regular.woff") format("woff"), url("../fonts/MinionPro-Regular.otf") format("opentype");
	font-style: normal;
	font-weight: 400;
}

h1,
h2 {
	font-family: 'Oswald', sans-serif;
}

h2 {
	@include fluidProp('font-size', 35, 56);
	line-height: 1.33em;
}

h3 {
	@include fluidProp('font-size', 23, 26);
}

b,
strong {
	font-weight: 700;
}