.columns {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--pageMarginTotal);
  width: 100%;
  column-gap: var(--gutter);
  --columns: 12;

  @media all and (max-width: $breakS) {
    row-gap: var(--gutter);
  }

  .columns {
    padding: 0;
  }

  &.mobile-reverse {
    @media all and (max-width: $breakS) {
      flex-direction: column-reverse;
    }
  }

  &.no-gutter {
    --gutter: 0;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.full-bleed {
  --pageMarginTotal: 0;
}

.col {
  flex: 1;

  @media all and (max-width: $breakS) {
    flex: none;
    width: 100%;
  }
}

.col[class^='col-'],
.col[class*=' col-'] {
  flex: none;
  width: calc((100% - (var(--gutter) * ((var(--columns) / var(--span)) - 1))) / (var(--columns) / var(--span)));
}

.col[class^='offset-'],
.col[class*=' offset-'] {
  margin-left: calc((100% - (var(--gutter) * ((var(--columns) / var(--offset)) - 1))) / (var(--columns) / var(--offset)) + var(--gutter));
}

.col[class^='offset-s-'],
.col[class*=' offset-s-'] {
  margin-left: calc((100% - (var(--gutter) * ((var(--columns) / var(--offset)) - 1))) / (var(--columns) / var(--offset)) + var(--gutter)) !important;
}

.col-1 {
  --span: 1;
}

.col-2 {
  --span: 2;
}

.col-3 {
  --span: 3;
}

.col-4 {
  --span: 4;
}

.col-5 {
  --span: 5;
}

.col-6 {
  --span: 6;
}

.col-7 {
  --span: 7;
}

.col-8 {
  --span: 8;
}

.col-9 {
  --span: 9;
}

.col-10 {
  --span: 10;
}

.col-11 {
  --span: 11;
}

.col-12 {
  --span: 12;
}

.col-13 {
  --span: 13;
}

.col-14 {
  --span: 14;
}

.col-15 {
  --span: 15;
}

.col-16 {
  --span: 16;
}

.col-17 {
  --span: 17;
}

.col-18 {
  --span: 18;
}

.col-19 {
  --span: 19;
}

.col-20 {
  --span: 20;
}

.col-21 {
  --span: 21;
}

.col-22 {
  --span: 22;
}

.col-23 {
  --span: 23;
}

.col-24 {
  --span: 24;
}

.offset-1 {
  --offset: 1;
}

.offset-2 {
  --offset: 2;
}

.offset-3 {
  --offset: 3;
}

.offset-4 {
  --offset: 4;
}

.offset-5 {
  --offset: 5;
}

.offset-6 {
  --offset: 6;
}

.offset-7 {
  --offset: 7;
}

.offset-8 {
  --offset: 8;
}

.offset-9 {
  --offset: 9;
}

.offset-10 {
  --offset: 10;
}

.offset-11 {
  --offset: 11;
}

.offset-12 {
  --offset: 12;
}

.offset-13 {
  --offset: 13;
}

.offset-14 {
  --offset: 14;
}

.offset-15 {
  --offset: 15;
}

.offset-16 {
  --offset: 16;
}

.offset-17 {
  --offset: 17;
}

.offset-18 {
  --offset: 18;
}

.offset-19 {
  --offset: 19;
}

.offset-20 {
  --offset: 20;
}

.offset-21 {
  --offset: 21;
}

.offset-22 {
  --offset: 22;
}

.offset-23 {
  --offset: 23;
}

.col-l-1 {
  @media all and (max-width: $breakL) {
    --span: 1;
  }
}

.col-l-2 {
  @media all and (max-width: $breakL) {
    --span: 2;
  }
}

.col-l-3 {
  @media all and (max-width: $breakL) {
    --span: 3;
  }
}

.col-l-4 {
  @media all and (max-width: $breakL) {
    --span: 4;
  }
}

.col-l-5 {
  @media all and (max-width: $breakL) {
    --span: 5;
  }
}

.col-l-6 {
  @media all and (max-width: $breakL) {
    --span: 6;
  }
}

.col-l-7 {
  @media all and (max-width: $breakL) {
    --span: 7;
  }
}

.col-l-8 {
  @media all and (max-width: $breakL) {
    --span: 8;
  }
}

.col-l-9 {
  @media all and (max-width: $breakL) {
    --span: 9;
  }
}

.col-l-10 {
  @media all and (max-width: $breakL) {
    --span: 10;
  }
}

.col-l-11 {
  @media all and (max-width: $breakL) {
    --span: 11;
  }
}

.col-l-12 {
  @media all and (max-width: $breakL) {
    --span: 12;
  }
}

.col-l-13 {
  @media all and (max-width: $breakL) {
    --span: 13;
  }
}

.col-l-14 {
  @media all and (max-width: $breakL) {
    --span: 14;
  }
}

.col-l-15 {
  @media all and (max-width: $breakL) {
    --span: 15;
  }
}

.col-l-16 {
  @media all and (max-width: $breakL) {
    --span: 16;
  }
}

.col-l-17 {
  @media all and (max-width: $breakL) {
    --span: 17;
  }
}

.col-l-18 {
  @media all and (max-width: $breakL) {
    --span: 18;
  }
}

.col-l-19 {
  @media all and (max-width: $breakL) {
    --span: 19;
  }
}

.col-l-20 {
  @media all and (max-width: $breakL) {
    --span: 20;
  }
}

.col-l-21 {
  @media all and (max-width: $breakL) {
    --span: 21;
  }
}

.col-l-22 {
  @media all and (max-width: $breakL) {
    --span: 22;
  }
}

.col-l-23 {
  @media all and (max-width: $breakL) {
    --span: 23;
  }
}

.col-l-24 {
  @media all and (max-width: $breakL) {
    --span: 24;
  }
}

.offset-l-0 {
  @media all and (max-width: $breakL) {
    margin-left: 0 !important;
  }
}

.offset-l-1 {
  @media all and (max-width: $breakL) {
    --offset: 1;
  }
}

.offset-l-2 {
  @media all and (max-width: $breakL) {
    --offset: 2;
  }
}

.offset-l-3 {
  @media all and (max-width: $breakL) {
    --offset: 3;
  }
}

.offset-l-4 {
  @media all and (max-width: $breakL) {
    --offset: 4;
  }
}

.offset-l-5 {
  @media all and (max-width: $breakL) {
    --offset: 5;
  }
}

.offset-l-6 {
  @media all and (max-width: $breakL) {
    --offset: 6;
  }
}

.offset-l-7 {
  @media all and (max-width: $breakL) {
    --offset: 7;
  }
}

.offset-l-8 {
  @media all and (max-width: $breakL) {
    --offset: 8;
  }
}

.offset-l-9 {
  @media all and (max-width: $breakL) {
    --offset: 9;
  }
}

.offset-l-10 {
  @media all and (max-width: $breakL) {
    --offset: 10;
  }
}

.offset-l-11 {
  @media all and (max-width: $breakL) {
    --offset: 11;
  }
}

.offset-l-12 {
  @media all and (max-width: $breakL) {
    --offset: 12;
  }
}

.offset-l-13 {
  @media all and (max-width: $breakL) {
    --offset: 13;
  }
}

.offset-l-14 {
  @media all and (max-width: $breakL) {
    --offset: 14;
  }
}

.offset-l-15 {
  @media all and (max-width: $breakL) {
    --offset: 15;
  }
}

.offset-l-16 {
  @media all and (max-width: $breakL) {
    --offset: 16;
  }
}

.offset-l-17 {
  @media all and (max-width: $breakL) {
    --offset: 17;
  }
}

.offset-l-18 {
  @media all and (max-width: $breakL) {
    --offset: 18;
  }
}

.offset-l-19 {
  @media all and (max-width: $breakL) {
    --offset: 19;
  }
}

.offset-l-20 {
  @media all and (max-width: $breakL) {
    --offset: 20;
  }
}

.offset-l-21 {
  @media all and (max-width: $breakL) {
    --offset: 21;
  }
}

.offset-l-22 {
  @media all and (max-width: $breakL) {
    --offset: 22;
  }
}

.offset-l-23 {
  @media all and (max-width: $breakL) {
    --offset: 23;
  }
}

.col-m-1 {
  @media all and (max-width: $breakM) {
    --span: 1;
  }
}

.col-m-2 {
  @media all and (max-width: $breakM) {
    --span: 2;
  }
}

.col-m-3 {
  @media all and (max-width: $breakM) {
    --span: 3;
  }
}

.col-m-4 {
  @media all and (max-width: $breakM) {
    --span: 4;
  }
}

.col-m-5 {
  @media all and (max-width: $breakM) {
    --span: 5;
  }
}

.col-m-6 {
  @media all and (max-width: $breakM) {
    --span: 6;
  }
}

.col-m-7 {
  @media all and (max-width: $breakM) {
    --span: 7;
  }
}

.col-m-8 {
  @media all and (max-width: $breakM) {
    --span: 8;
  }
}

.col-m-9 {
  @media all and (max-width: $breakM) {
    --span: 9;
  }
}

.col-m-10 {
  @media all and (max-width: $breakM) {
    --span: 10;
  }
}

.col-m-11 {
  @media all and (max-width: $breakM) {
    --span: 11;
  }
}

.col-m-12 {
  @media all and (max-width: $breakM) {
    --span: 12;
  }
}

.col-m-13 {
  @media all and (max-width: $breakM) {
    --span: 13;
  }
}

.col-m-14 {
  @media all and (max-width: $breakM) {
    --span: 14;
  }
}

.col-m-15 {
  @media all and (max-width: $breakM) {
    --span: 15;
  }
}

.col-m-16 {
  @media all and (max-width: $breakM) {
    --span: 16;
  }
}

.col-m-17 {
  @media all and (max-width: $breakM) {
    --span: 17;
  }
}

.col-m-18 {
  @media all and (max-width: $breakM) {
    --span: 18;
  }
}

.col-m-19 {
  @media all and (max-width: $breakM) {
    --span: 19;
  }
}

.col-m-20 {
  @media all and (max-width: $breakM) {
    --span: 20;
  }
}

.col-m-21 {
  @media all and (max-width: $breakM) {
    --span: 21;
  }
}

.col-m-22 {
  @media all and (max-width: $breakM) {
    --span: 22;
  }
}

.col-m-23 {
  @media all and (max-width: $breakM) {
    --span: 23;
  }
}

.col-m-24 {
  @media all and (max-width: $breakM) {
    --span: 24;
  }
}

.offset-m-0 {
  @media all and (max-width: $breakM) {
    margin-left: 0 !important;
  }
}

.offset-m-1 {
  @media all and (max-width: $breakM) {
    --offset: 1;
  }
}

.offset-m-2 {
  @media all and (max-width: $breakM) {
    --offset: 2;
  }
}

.offset-m-3 {
  @media all and (max-width: $breakM) {
    --offset: 3;
  }
}

.offset-m-4 {
  @media all and (max-width: $breakM) {
    --offset: 4;
  }
}

.offset-m-5 {
  @media all and (max-width: $breakM) {
    --offset: 5;
  }
}

.offset-m-6 {
  @media all and (max-width: $breakM) {
    --offset: 6;
  }
}

.offset-m-7 {
  @media all and (max-width: $breakM) {
    --offset: 7;
  }
}

.offset-m-8 {
  @media all and (max-width: $breakM) {
    --offset: 8;
  }
}

.offset-m-9 {
  @media all and (max-width: $breakM) {
    --offset: 9;
  }
}

.offset-m-10 {
  @media all and (max-width: $breakM) {
    --offset: 10;
  }
}

.offset-m-11 {
  @media all and (max-width: $breakM) {
    --offset: 11;
  }
}

.offset-m-12 {
  @media all and (max-width: $breakM) {
    --offset: 12;
  }
}

.offset-m-13 {
  @media all and (max-width: $breakM) {
    --offset: 13;
  }
}

.offset-m-14 {
  @media all and (max-width: $breakM) {
    --offset: 14;
  }
}

.offset-m-15 {
  @media all and (max-width: $breakM) {
    --offset: 15;
  }
}

.offset-m-16 {
  @media all and (max-width: $breakM) {
    --offset: 16;
  }
}

.offset-m-17 {
  @media all and (max-width: $breakM) {
    --offset: 17;
  }
}

.offset-m-18 {
  @media all and (max-width: $breakM) {
    --offset: 18;
  }
}

.offset-m-19 {
  @media all and (max-width: $breakM) {
    --offset: 19;
  }
}

.offset-m-20 {
  @media all and (max-width: $breakM) {
    --offset: 20;
  }
}

.offset-m-21 {
  @media all and (max-width: $breakM) {
    --offset: 21;
  }
}

.offset-m-22 {
  @media all and (max-width: $breakM) {
    --offset: 22;
  }
}

.offset-m-23 {
  @media all and (max-width: $breakM) {
    --offset: 23;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-13,
.col-14,
.col-15,
.col-16,
.col-17,
.col-18,
.col-19,
.col-20,
.col-21,
.col-22,
.col-23,
.col-24,
.col-l-1,
.col-l-2,
.col-l-3,
.col-l-4,
.col-l-5,
.col-l-6,
.col-l-7,
.col-l-8,
.col-l-9,
.col-l-10,
.col-l-11,
.col-l-12,
.col-l-13,
.col-l-14,
.col-l-15,
.col-l-16,
.col-l-17,
.col-l-18,
.col-l-19,
.col-l-20,
.col-l-21,
.col-l-22,
.col-l-23,
.col-l-24,
.col-m-1,
.col-m-2,
.col-m-3,
.col-m-4,
.col-m-5,
.col-m-6,
.col-m-7,
.col-m-8,
.col-m-9,
.col-m-10,
.col-m-11,
.col-m-12,
.col-m-13,
.col-m-14,
.col-m-15,
.col-m-16,
.col-m-17,
.col-m-18,
.col-m-19,
.col-m-20,
.col-m-21,
.col-m-22,
.col-m-23,
.col-m-24 {
  @media all and (max-width: $breakS) {
    --span: 12;
  }
}

.offset-1,
.offset-2,
.offset-3,
.offset-4,
.offset-5,
.offset-6,
.offset-7,
.offset-8,
.offset-9,
.offset-10,
.offset-11,
.offset-12,
.offset-13,
.offset-14,
.offset-15,
.offset-16,
.offset-17,
.offset-18,
.offset-19,
.offset-20,
.offset-21,
.offset-22,
.offset-23 {
  @media all and (max-width: $breakS) {
    --offset: 0;
    margin-left: 0 !important;
  }
}

.col-s-1 {
  @media all and (max-width: $breakS) {
    --span: 1;
  }
}

.col-s-2 {
  @media all and (max-width: $breakS) {
    --span: 2;
  }
}

.col-s-3 {
  @media all and (max-width: $breakS) {
    --span: 3;
  }
}

.col-s-4 {
  @media all and (max-width: $breakS) {
    --span: 4;
  }
}

.col-s-5 {
  @media all and (max-width: $breakS) {
    --span: 5;
  }
}

.col-s-6 {
  @media all and (max-width: $breakS) {
    --span: 6;
  }
}

.col-s-7 {
  @media all and (max-width: $breakS) {
    --span: 7;
  }
}

.col-s-8 {
  @media all and (max-width: $breakS) {
    --span: 8;
  }
}

.col-s-9 {
  @media all and (max-width: $breakS) {
    --span: 9;
  }
}

.col-s-10 {
  @media all and (max-width: $breakS) {
    --span: 10;
  }
}

.col-s-11 {
  @media all and (max-width: $breakS) {
    --span: 11;
  }
}

.col-s-12 {
  @media all and (max-width: $breakS) {
    --span: 12;
  }
}

.col-s-13 {
  @media all and (max-width: $breakS) {
    --span: 13;
  }
}

.col-s-14 {
  @media all and (max-width: $breakS) {
    --span: 14;
  }
}

.col-s-15 {
  @media all and (max-width: $breakS) {
    --span: 15;
  }
}

.col-s-16 {
  @media all and (max-width: $breakS) {
    --span: 16;
  }
}

.col-s-17 {
  @media all and (max-width: $breakS) {
    --span: 17;
  }
}

.col-s-18 {
  @media all and (max-width: $breakS) {
    --span: 18;
  }
}

.col-s-19 {
  @media all and (max-width: $breakS) {
    --span: 19;
  }
}

.col-s-20 {
  @media all and (max-width: $breakS) {
    --span: 20;
  }
}

.col-s-21 {
  @media all and (max-width: $breakS) {
    --span: 21;
  }
}

.col-s-22 {
  @media all and (max-width: $breakS) {
    --span: 22;
  }
}

.col-s-23 {
  @media all and (max-width: $breakS) {
    --span: 23;
  }
}

.col-s-24 {
  @media all and (max-width: $breakS) {
    --span: 24;
  }
}

.offset-s-0 {
  @media all and (max-width: $breakS) {
    margin-left: 0 !important;
  }
}

.offset-s-1 {
  @media all and (max-width: $breakS) {
    --offset: 1;
  }
}

.offset-s-2 {
  @media all and (max-width: $breakS) {
    --offset: 2;
  }
}

.offset-s-3 {
  @media all and (max-width: $breakS) {
    --offset: 3;
  }
}

.offset-s-4 {
  @media all and (max-width: $breakS) {
    --offset: 4;
  }
}

.offset-s-5 {
  @media all and (max-width: $breakS) {
    --offset: 5;
  }
}

.offset-s-6 {
  @media all and (max-width: $breakS) {
    --offset: 6;
  }
}

.offset-s-7 {
  @media all and (max-width: $breakS) {
    --offset: 7;
  }
}

.offset-s-8 {
  @media all and (max-width: $breakS) {
    --offset: 8;
  }
}

.offset-s-9 {
  @media all and (max-width: $breakS) {
    --offset: 9;
  }
}

.offset-s-10 {
  @media all and (max-width: $breakS) {
    --offset: 10;
  }
}

.offset-s-11 {
  @media all and (max-width: $breakS) {
    --offset: 11;
  }
}

.offset-s-12 {
  @media all and (max-width: $breakS) {
    --offset: 12;
  }
}

.offset-s-13 {
  @media all and (max-width: $breakS) {
    --offset: 13;
  }
}

.offset-s-14 {
  @media all and (max-width: $breakS) {
    --offset: 14;
  }
}

.offset-s-15 {
  @media all and (max-width: $breakS) {
    --offset: 15;
  }
}

.offset-s-16 {
  @media all and (max-width: $breakS) {
    --offset: 16;
  }
}

.offset-s-17 {
  @media all and (max-width: $breakS) {
    --offset: 17;
  }
}

.offset-s-18 {
  @media all and (max-width: $breakS) {
    --offset: 18;
  }
}

.offset-s-19 {
  @media all and (max-width: $breakS) {
    --offset: 19;
  }
}

.offset-s-20 {
  @media all and (max-width: $breakS) {
    --offset: 20;
  }
}

.offset-s-21 {
  @media all and (max-width: $breakS) {
    --offset: 21;
  }
}

.offset-s-22 {
  @media all and (max-width: $breakS) {
    --offset: 22;
  }
}

.offset-s-23 {
  @media all and (max-width: $breakS) {
    --offset: 23;
  }
}

.show-small {
  display: none;

  @media all and (max-width: $breakS) {
    display: flex;
  }
}

.hide-small {
  @media all and (max-width: $breakS) {
    display: none;
  }
}

.small-right {
  @media all and (max-width: $breakS) {
    text-align: right;
  }
}